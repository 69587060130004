<template>
	<div class="partidaFormHeader puiformheader">
		<v-layout row wrap>
			<v-flex xs12 md2>
				<pui-field :label="$t('header.partida.numdeclaracion')" :value="getNumdeclaracionValue"></pui-field>
			</v-flex>
			<v-flex xs12 md2>
				<pui-field :label="$t('header.partida.tipodeclaracion')" :value="getTipodeclaracionValue"></pui-field>
			</v-flex>
			<v-flex xs12 md2>
				<pui-field :label="$t('header.partida.numpartida')" :value="getNumpartidaValue"></pui-field>
			</v-flex>
			<v-flex xs12 md2>
				<pui-field :label="$t('header.partida.numdocumento')" :value="getNumdocumentoValue"></pui-field>
			</v-flex>
			<v-flex xs12 md2>
				<pui-field :label="$t('header.partida.tipodoc')" :value="getTipodocValue"></pui-field>
			</v-flex>
			<v-flex xs12 md2>
				<pui-field :label="$t('header.partida.numpartidaadu')" :value="getNumpartidaaduValue"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'partida-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'partida'
		};
	},
	computed: {
		getNumdeclaracionValue() {
			return this.model && this.model.numdeclaracion ? this.model.numdeclaracion : '-';
		},
		getTipodeclaracionValue() {
			return this.model && this.model.tipodeclaracion ? this.model.tipodeclaracion : '-';
		},
		getNumpartidaValue() {
			return this.model && this.model.numpartida ? this.model.numpartida : '-';
		},
		getNumdocumentoValue() {
			return this.model && this.model.numdocumento ? this.model.numdocumento : '-';
		},
		getTipodocValue() {
			return this.model && this.model.tipodoc ? this.model.tipodoc : '-';
		},
		getNumpartidaaduValue() {
			return this.model && this.model.numpartidaadu ? this.model.numpartidaadu : '-';
		}
	}
};
</script>
